<script>
  import FixedHeader from 'vue-fixed-header'

  export default {
    components: {
        FixedHeader
    },
    data() {
      return {
        currentSlide: 0,
        slider: null,
        bannerZima: {
          title: "SKI & SNB",
          subtitle: "LYŽIARSKA A SNOWBOARDOVÁ<br>VÝSTROJ ZA SKVELÉ CENY",
          images: [
            "willem-de-meyer-E7EUYeN-IyY-unsplash.jpg", // https://skiservis.imgix.net/willem-de-meyer-E7EUYeN-IyY-unsplash.jpg?w=400&h=600&fit=crop&crop=edges&auto=format&q=60
            "boarder.jpg",
            "two-man-hiking-on-snow-mountain-869258.jpg",
            "nicolai-berntsen-VXiG4N229uY-unsplash.jpg"
          ]
        },
        bannerLeto: {
          title: "LETO NA HORÁCH",
          subtitle: "ELEKTROBICYKLE, BICYKLE a KOLOBEŽKY<br>POŽIČANIE ZA SKVELÉ CENY",
          images: [
            "leto/nick-rickert-fsT6vpMeWEY-unsplash.jpg",
            "leto/banner1.jpg",
            "leto/marek-piwnicki-1zv1p3jvDBo-unsplash.jpg",
            "leto/kolobezka.jpg",
            "leto/orjan-windsland-rf1LLx8KFWc-unsplash.jpg",
            "leto/crussis.jpg"
          ]
        }
      }
    },
    computed: {
      isHeaderSlim() {
        return this.$route.name !== "home"
      },
      banner() {
        if (this.$isSummer) {
          return this.bannerLeto
        } else {
          return this.bannerZima
        }
      }
    },
    methods: {
      toggleMobileNav() {
        this.$root.mobileNavVisible = !this.$root.mobileNavVisible;
      }
    },
    created() {
      this.slider = setInterval(() => {
        if (!this.isHeaderSlim) {
          this.currentSlide++
          if (this.currentSlide === this.banner.images.length) {
            this.currentSlide = 0
          }
        }
      }, 6000);
    },
    beforeDestroy () {
       clearInterval(this.slider)
    }
  }
</script>

<template>
  <div id="app">
    <header :class="{ 'slim': isHeaderSlim }">
      <div class="image">
        <transition name="slide-fade" mode="in-out">
          <template v-for="(image, index) in banner.images">
            <picture :key="`slide${index}`" alt="" v-if="currentSlide === index">
              <source media="(max-aspect-ratio: 4/5)" 
                :srcset="`https://skiservis.imgix.net/${image}?w=500&h=900&fit=crop&crop=edges&auto=format&dpr=1 1x,
                          https://skiservis.imgix.net/${image}?w=500&h=900&fit=crop&crop=edges&auto=format&dpr=2 2x,
                          https://skiservis.imgix.net/${image}?w=500&h=900&fit=crop&crop=edges&auto=format&dpr=3 3x`"
              >
              <source media="(max-width: 850px)" 
                :srcset="`https://skiservis.imgix.net/${image}?w=800&h=1200&fit=crop&crop=edges&auto=format&dpr=1 1x,
                          https://skiservis.imgix.net/${image}?w=800&h=1200&fit=crop&crop=edges&auto=format&dpr=2 2x,
                          https://skiservis.imgix.net/${image}?w=800&h=1200&fit=crop&crop=edges&auto=format&dpr=3 3x`"
              >
              <source media="(min-width: 1366px)" 
                :srcset="`https://skiservis.imgix.net/${image}?w=1920&h=1280&auto=format&dpr=1 1x,
                          https://skiservis.imgix.net/${image}?w=1920&h=1280&auto=format&dpr=2 2x,
                          https://skiservis.imgix.net/${image}?w=1920&h=1280&auto=format&dpr=3 3x`"
              >
              <img :src="`https://skiservis.imgix.net/${image}?w=1200&h=800&auto=format`" alt="" width="1200" height="800"
                :srcset="`https://skiservis.imgix.net/${image}?w=1200&h=800&auto=format&dpr=1 1x,
                          https://skiservis.imgix.net/${image}?w=1200&h=800&auto=format&dpr=2 2x,
                          https://skiservis.imgix.net/${image}?w=1200&h=800&auto=format&dpr=3 3x`"
              >
            </picture>
          </template>
        </transition>
      </div>
      <div class="top" :class="{ 'leto': $isSummer }">
        <fixed-header :threshold="700" headerClass="" fixedClass="floating-nav-bars">
          <button class="hide-desktop" @click="toggleMobileNav" aria-label="Ukáž navigáciu"><font-awesome-icon icon="bars" /></button>
        </fixed-header>
        <a href="tel:+421905357133" class="show-desktop" title=""><img src="@/assets/icons/phone.svg" alt="mobil" class="icon-left" width="22" height="22"> +421 905 357 133</a>
        <router-link to="/" class="logo"><img alt="Skiservis Niňaj" src="@/assets/logo.svg" width="210" height="70"></router-link>
        <a href="mailto:skiservis@skiservis.sk" class="show-desktop email-address" title="">skiservis@skiservis.sk</a>
        <span class="social-icons">
          <a class="hide-desktop" href="tel:+421905357133" title="Ski Niňaj mobilný kontakt"><img src="@/assets/icons/phone.svg" alt="mobil" class="icon-left" width="32" height="32"></a>
          <a href="https://www.instagram.com/skininaj/" title="Ski Niňaj Instagram Profile" target="_blank" class="hide-mobile"><img src="@/assets/icons/instagram.svg" alt="instagram" class="icon" width="24" height="24"></a>
          <a href="https://www.facebook.com/skininaj" title="Ski Niňaj Facebook Page" target="_blank" class="hide-mobile"><img src="@/assets/icons/facebook.svg" alt="facebook" class="icon" width="24" height="24"></a>
        </span>
      </div>
      <div class="banner" v-show="!isHeaderSlim">
        <div class="title">{{ banner.title }}</div>
        <div class="subtitle" v-html="banner.subtitle"></div>
        <div class="motto"><b>30 ROKOV</b><img alt="SKÚSENOSTÍ" src="@/assets/icons/experience.svg" width="22" height="22">SKÚSENOSTÍ</div>
      </div>
    </header>
    <fixed-header :threshold="800">
      <nav :class="$root.mobileNavVisible ? 'nav-visible' : ''">
        <div class="nav-wrapper">
          <router-link :to="{ name: 'home' }"><img alt="Domov" src="@/assets/icons/cabin.svg" width="50" height="62"> DOMOV</router-link>
          <template v-if="$isSummer">
            <router-link :to="{ name: 'pozicovna-elektrobicyklov' }"><img alt="Elektrobicykle" src="@/assets/icons/electric-bike3.svg" width="62" height="62" style="padding: 0; height: 62px"> ELEKTROBICYKLE</router-link>
            <router-link :to="{ name: 'pozicovna-bicyklov' }"><img alt="Bicykle" src="@/assets/icons/bike.svg" width="50" height="62"> BICYKLE</router-link>
            <a href="https://www.kolobezkyliptov.sk/" target="_blank"><img alt="Kolobežky" src="@/assets/icons/kick-scooter.svg" width="50" height="62"> KOLOBEŽKY</a>
          </template>
          <router-link :to="{ name: 'pozicovna' }"><img alt="Požičovňa" src="@/assets/icons/ski.svg" width="50" height="62"> POŽIČOVŇA</router-link>
          <router-link :to="{ name: 'skiservis' }"><img alt="Skiservis" src="@/assets/icons/snowboard.svg" width="50" height="62"> SKISERVIS</router-link>
          <router-link :to="{ name: 'predaj' }"><img alt="Predaj" src="@/assets/icons/boots.svg" width="50" height="62"> PREDAJ</router-link>
          <router-link :to="{ name: 'celosezonne' }"><img alt="Celosezónne požičanie" src="@/assets/icons/skier.svg" width="50" height="62"> CELOSEZÓNNE POŽIČANIE</router-link>
          <template v-if="!$isSummer">
            <router-link :to="{ name: 'pozicovna-elektrobicyklov' }"><img alt="Elektrobicykle" src="@/assets/icons/electric-bike3.svg" width="62" height="62" style="padding: 0; height: 62px"> ELEKTROBICYKLE</router-link>
            <router-link :to="{ name: 'pozicovna-bicyklov' }"><img alt="Bicykle" src="@/assets/icons/bike.svg" width="50" height="62"> BICYKLE</router-link>
            <a href="https://www.kolobezkyliptov.sk/" target="_blank"><img alt="Kolobežky" src="@/assets/icons/kick-scooter.svg" width="50" height="62"> KOLOBEŽKY</a>
          </template>
          <router-link :to="{ name: 'kontakt' }"><img alt="Kontakt" src="@/assets/icons/smartphone-with-a-smile.svg" width="50" height="62"> KONTAKT</router-link>
        </div>
      </nav>
    </fixed-header>
    <router-view/>
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700,900|Source+Sans+Pro:400,600&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Montserrat+Subrayada&display=swap&subset=latin-ext');

.slide-fade-enter-active {
  transition: all 3s ease;

  img {
    transition: all 3s ease;
  }
}

.slide-fade-leave-active {
  transition: all 3s ease;

  img {
    transition: all 3s ease;
  }
}

.slide-fade-enter, .slide-fade-leave-to {
  opacity: 0;

  img {
    filter: blur(10px);
    transform: scale(1.2) rotate(3deg);
  }
}

html, body {
  margin: 0;
  padding: 0;
}

html {
  background-color: #fff;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
}

body {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
}

p {
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
  }
}

img {
  height: auto;
  max-width: 100%;
}

#app {
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  margin: 0;
  padding: 0;

  @media (min-width: 1200px) {
    min-width: 1200px;
  }
}

.floating-nav-bars {
  position: fixed !important;
  background: #1a5788 !important;
  border-radius: 50px;
  font-size: 1.9rem !important;
  top: 10px;
  left: 10px;
  line-height: 1;
  height: 0.5in;
  width: 0.5in;
  padding: 0 !important;
  text-align: center !important;
  z-index: 100;
  transition: all 450ms cubic-bezier(0,.9,.25,1);
}

header {
  height: 750px;
  overflow: hidden;
  position: relative;
  font-family: "Montserrat";
  transition: height 450ms cubic-bezier(0,.9,.25,1);

  @media (max-width: 850px) {
    height: 100vh;
  }

  &.slim {
    height: 100px;

    .image {
      img {
        margin-top: 0;
      }
    }

    .logo {
      img {
        transform: scale(0.9);
      }
    }
  }

  .image {
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 750px;
      /*transition: margin 450ms cubic-bezier(0,.9,.25,1);*/

      @media (max-aspect-ratio: 4/5) {
        width: auto;
        height: auto;
        max-width: none;
        min-height: 100vh;
        min-width: 100vw;
      }

      @media (min-width: 850px) and (max-width: 1200px) {
        width: auto;
        max-width: none;
      }

      @media (max-width: 8px) {
        height: 100vh;
        min-height: auto;
      }
    }
  }

  .top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    line-height: 16px;
    background: linear-gradient(180deg, #1a5788, transparent);

    @media (max-width: 850px) {
      justify-content: space-between;
      padding: 0 0.5rem;
    }

    &.leto {
      background: linear-gradient(180deg, #72a94e, transparent);
    }

    @supports (-webkit-hyphens:none) {
      .social-icons {
        flex-basis: 3.5rem;
      }
    }

    a {
      color: #fff;
    }

    button {
      background: none;
      border: none;
      outline: none;
      color: #fff;
      font-size: 2rem;
      text-align: left;
      padding-left: 1.25rem;

      @media (min-width: 601px) {
        flex-basis: 12rem;
      }
    }

    .logo {
      @media (min-width: 851px) {
        &:before, &:after {
          content: "";
          display: inline-block;
          width: 20px;
          height: 35px;
          border-top: 1px solid rgba(255,255,255,.8);
          margin: 0 1rem;
        }
      }
      img {
        height: 60px;
        margin: 0 0.75rem;
        transition: transform 450ms ease-out;

        @media (min-width: 851px) {
          height: 70px;
          margin: 0;
        }
      }
    }

    .icon {
      width: 2.1rem;
      margin: 0 .95rem;

      @media (max-width: 640px) {
        width: 1.6rem;
        margin: 0.4rem 0.95rem;
      }

      @media (min-width: 851px) {
        width: auto;
        height: 1.5rem;
        margin: 0 0 0 1rem;
      }
    }

    .icon-left {
      width: 2rem;
      margin: 0 1rem;

      @media (min-width: 600px) and (max-width: 640px) {
        width: 1.5rem;
        margin: 0.4rem 1rem;
      }

      @media (min-width: 851px) {
        width: auto;
        height: 1.4rem;
        margin: 0 1rem 0 0;
        vertical-align: middle;
        margin-left: 75px;
      }
    }
  }

  .banner {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    height: 630px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-shadow: 0 0 50px rgba(0,0,0,.4);
    color: #fff;

    @media (max-aspect-ratio: 4/5) {
      align-items: start;
    }

    @media (max-width: 850px) {
      height: auto;
      bottom: 0;
    }

    .title {
      color: #fff;
      font-family: "Montserrat Subrayada";
      font-weight: bold;
      font-size: 100px;
      line-height: 1.125;

      @media (max-aspect-ratio: 4/5) {
        text-align: left;
        font-size: 9vh;
        padding: 0 2vh;
      }
    }
    .subtitle {
      color: #fff;
      font-size: 32px;
      text-align: center;
      line-height: 1.25;

      @media (max-aspect-ratio: 4/5) {
        text-align: left;
        font-size: 4vh;
        padding: 0 2vh;
      }
    }
    .motto {
      position: absolute;
      bottom: 0;
      color: #000;
      height: 60px;
      display: flex;
      align-items: center;
      font-size: 1.3rem;
      text-shadow: 0px 0 2px rgba(255,255,255,1), 0px 0 4px rgba(255,255,255,1), 0px 0 15px rgba(255,255,255,.8);
      animation: blur-in 3s ease-in-out 2s 1 normal;

      @media (max-width: 850px) {
        height: 80px;
        left: 0;
        right: 0;
        justify-content: center;
        font-size: 1.4rem;
        text-shadow: 0px 0 2px rgba(255,255,255,1), 0px 0 4px rgba(255,255,255,1), 0px 0 10px rgba(255,255,255,1);
      }

      @media (max-width: 400px) {
        font-size: 1.2rem;
      }

      img {
        height: 1.4rem;
        margin: 0 .75rem;
      }
    }
  }
}

@keyframes blur-in {
  0% {
    filter: blur(0);
    opacity: 1;
  }
  20% {
    filter: blur(10px);
    opacity: .8;
    color: #fff;
  }
  100% {
    filter: blur(0);
    opacity: 1;
  }
}

nav {
  top: -100px;
  height: 100px;
  background: #000;
  font-family: "Montserrat";
  transition: top 450ms ease-out, left 450ms ease-out, opacity 250ms ease-out;

  @media (max-width: 850px) {
    position: fixed;
    top: 0;
    left: 120px;
    right: 0;
    bottom: 0;
    min-height: 100vh;
    visibility: hidden;
    opacity: 0;
    z-index: 1000;

    &.nav-visible {
      left: 85px !important;
      visibility: visible;
      opacity: 1;
    }
  }

  &.vue-fixed-header--isFixed {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 5;
  }

  .nav-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: 850px) {
      justify-content: flex-start;
      flex-direction: column;
    }

    a {
      color: #fff;
      text-decoration: none;
      font-weight: bold;
      padding: 10px 20px 0;
      font-size: 16px;
      text-align: center;

      @media (max-width: 850px) {
        text-align: left;
        padding: 7px 20px;
        white-space: nowrap;
      }

      @media (max-width: 450px) {
        font-size: 14px;
      }

      img {
        display: block;
        filter: invert(1);
        height: 50px;
        margin: auto;
        padding: 0 0 12px;

        @media (max-width: 850px) {
          display: inline;
          width: 5vh;
          height: auto;
          vertical-align: middle;
          height: auto;
          margin: 0 1.5rem 0 0;
          padding: 0.5rem 0;
        }
      }

      &:hover {
        background: #6cafcc;
      }
    }
  }
}

h1, h2, h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.button-action {
  display: inline-block;
  color: #fff;
  background: #66acca;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 15px;
  text-decoration: none;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
}
</style>
